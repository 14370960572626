import React from "react";
import "./Careers.css";
import benefitsIcon from "../../../src/images/social_media_platform/Benefits.png";
import { Grid } from "@material-ui/core";
import withUsPicture from "../../images/careers/WHY WORK WITH US.png";
import benefitsPicture from "../../images/careers/BENEFITS.png";
const Careers = () => {
  const benefits = [
    "Competitive Salary Benefits Package",
    "Travel Incentive",
    "SSS, Pagibig, Philhealth",
    "13th Month Pay",
    "Health Card",
    "Good working environment",
    "Career growth",
  ];

  return (
    <>
      <div id="why-work-with-us" align="left" className="container">
        <div className="row">
          <div align="right" className="col-lg-6 col-md-10 col-sm-12 mt-5">
            <img className="plant_image" src={withUsPicture} />
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12">
            <p className="why_work">WHY WORK</p>
            {/* <br /> */}
            <p className="with_us">WITH US?</p>
            <p
              id="benefits"
              className="col-lg-10 col-md-12 col-sm-6 work_with_text pb-5"
            >
              Connext-Holding Inc. ensures a holistic and more sustainable
              business model especially during the pandemic; creating more jobs
              for Filipinos and paving roads for more opportunities as we inch
              our way through these trying yet significant times.
            </p>
          </div>
        </div>
      </div>

      <Grid id="benefits" container align="left">
        <Grid item md={12} xs={12}>
          <div className="container  text-center col-md-4 p-1 benefits_box">
            <div className="col-12 text-center bg-connext-dark p-0 py-2">
              <p className="display-5 fw-bold">Benefits</p>
              <div className="awards_box"></div>
            </div>
          </div>

          <div
            id="benefits"
            className="container-fluid d-flex flex-column-reverse flex-md-row"
          >
            <div className="container p-4  mb-3 d-flex justify-content-center flex-column">
              <p className="lh-lg text-justify fs-4 mx-auto context">
                <div className="container d-flex">
                  <img src={benefitsIcon} className="benefits_icon" />
                  <div className=" my-auto">
                    <div className="icons__text">
                      <p>Competitive Salary Benefits Package</p>
                      <p>Travel Incentive</p>
                      <p>SSS, Pagibig, Philhealth</p>
                      <p>13th Month Pay</p>
                      <p>Health Card"</p>
                      <p>Good working environment</p>
                      <p>Career growth</p>
                    </div>
                  </div>
                </div>
              </p>
            </div>
            <div className="container">
              <img
                src={benefitsPicture}
                className="col-md-6 container plant_image"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Careers;
