import React from "react";
import "./Footer.css";
import connextLogo from "../../images/logo/2020 CONNEXT - HOLDING INC..png";
import logo1 from "../../images/social_media_platform/Facebook.png";
import logo2 from "../../images/social_media_platform/Youtube.png";
import logo3 from "../../images/social_media_platform/Linkedin.png";
import logo4 from "../../images/social_media_platform/Indeed.png";
import logo5 from "../../images/social_media_platform/Jobstreet.png";
import {
  BsFillTelephoneFill,
  BsFillPhoneFill,
  BsFillEnvelopeFill,
} from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

const Footer = () => {
  return (
    <div
      id="features"
      className="footer container-fluid border-top border-5 border-dark d-md-flex py-5 mt-3"
    >
      <div className="connext_footer_img">
        <img
          className="connext__pic"
          //  src="../../images/logo/CONNEXT INSURANCE AGENCY.png"
          src={connextLogo}
        />
      </div>
      <div className="container col-md-3 px-5">
        <p className="contact_text">CONTACT US!</p>
        <p>
          <span className="me-2 footer_icon">
            <BsFillTelephoneFill />
          </span>
          (02) 8820 3345
        </p>
        <p>
          <span className="me-2 footer_icon">
            <BsFillPhoneFill />
          </span>
          (0956) 523- 4101
        </p>
        <p>
          <span className="me-2 footer_icon">
            <BsFillEnvelopeFill />
          </span>
          admin@connextcgi.com
        </p>
      </div>
      <div className="container px-5">
        <p>
          <span className="me-2 footer_icon">
            <MdLocationOn />
          </span>
          2F MC Rillo Bldg. #1168 E. Rodriguez Sr. Ave. Barangay Mariana New
          Manila, Quezon City.
        </p>
        <div className="d-flex col-md-7">
          <div className="col-1 me-2">
            <a
              href="https://www.facebook.com/connextinsuranceagency"
              target="_blank"
            >
              <img src={logo1} width="20" />
            </a>
          </div>
          <div className="col-1 me-2">
            <a
              href="https://www.youtube.com/channel/UCIJiAQp3tILuEt2o0G1k-Wg"
              target="_blank"
            >
              <img
                src={logo2}
                width="25"
                // height="30"
              />
            </a>
          </div>
          <div className="col-1 me-2">
            <a
              href="https://www.linkedin.com/company/connext-holdings-inc"
              target="_blank"
            >
              <img
                src={logo3}
                width="20"
                // height="30"
              />
            </a>
          </div>
          <div className="col-1 me-2">
            <a href="https://ph.indeed.com/cmp/Connext-Holding" target="_blank">
              <img src={logo4} width="20" />
            </a>
          </div>
          <div className="col-1 me-2">
            <a
              href="https://www.jobstreet.com.ph/en/companies/156747555396502-connext-holding-inc"
              target="_blank"
            >
              <img src={logo5} width="20" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
