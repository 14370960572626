import React from "react";
import Slider from "react-slick";
import "./EventsAndHighlights.css";
import nextArrow from "../../images/EVENTS-AND-HIGHLIGHTS/nextArrow-removebg-preview.png";
import prevArrow from "../../images/EVENTS-AND-HIGHLIGHTS/prevArrow-removebg-preview.png";
import event1 from "../../images/EVENTS-AND-HIGHLIGHTS/Champions League Goes to Singapore - 2019.JPG";
import event2 from "../../images/EVENTS-AND-HIGHLIGHTS/Connext 2nd Anniversary at Iloilo Branch.jpg";
import event3 from "../../images/EVENTS-AND-HIGHLIGHTS/Connext Christmas Party - Dec2019.JPG";
import event4 from "../../images/EVENTS-AND-HIGHLIGHTS/Connext Goes to South Korea - 2019.jpg";
import event5 from "../../images/EVENTS-AND-HIGHLIGHTS/DSC_0462.JPG";
import event6 from "../../images/EVENTS-AND-HIGHLIGHTS/Iloilo Branch Motorcade - 2019.jpeg";
import event7 from "../../images/EVENTS-AND-HIGHLIGHTS/IMG_1491.JPG";
import event8 from "../../images/EVENTS-AND-HIGHLIGHTS/IMG_4012.JPG";
import event9 from "../../images/EVENTS-AND-HIGHLIGHTS/IMG_4549-2.jpg";
import event10 from "../../images/EVENTS-AND-HIGHLIGHTS/IMG_4599.jpg";
import event11 from "../../images/EVENTS-AND-HIGHLIGHTS/Livelihood Caravan with Ms. Earth - 2019.JPG";
import event12 from "../../images/EVENTS-AND-HIGHLIGHTS/Ms. Earth 2019 Tree Planting - 2019.JPG";
import event13 from "../../images/EVENTS-AND-HIGHLIGHTS/Ms. Earth Philippines Crowning Night - 2019.jpg";
import event14 from "../../images/EVENTS-AND-HIGHLIGHTS/Myhype IT Solutions Launching -  Dec2020.JPG";
import event15 from "../../images/EVENTS-AND-HIGHLIGHTS/Tara Trabaho Registration - July2021.JPG";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div>
      <img
        src={nextArrow}
        className="nextArrow"
        style={{
          height: 40,
          width: 40,
          right: "-5%",
          position: "absolute",
          right: "-5%",
          bottom: 130,
        }}
        onClick={onClick}
      />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div>
      <img
        src={prevArrow}
        className="prevArrow"
        style={{
          height: 40,
          width: 40,
          left: "-5%",
          position: "relative",
          top: 140,
        }}
        onClick={onClick}
      />
    </div>
  );
}

const EventsAndHighlights = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container position-relative pb-5 mb-5 eventTitle">
      <div className="col-12 text-center bg-connext-dark p-0 py-2">
        <p className="display-5 fw-bold">Events and Highlights</p>
        <div className="awards_box"></div>
      </div>
      <div className="container-fluid pt-5 ">
        <Slider {...settings}>
          <div className="container">
            <img
              src={event1}
              className="img-object-fit"
              style={{ height: 260, width: 410, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event2}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event3}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>

          <div className="container">
            <img
              src={event4}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event5}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event7}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event8}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event9}
              className="img-object-fit"
              style={{ height: 260, width: 410, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event10}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event11}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event12}
              className="img-object-fit"
              style={{ height: 266, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event13}
              className="img-object-fit"
              style={{ height: 260, width: 410, objectFit: "contain" }}
            />
          </div>
          <div className="container">
            <img
              src={event14}
              className="img-object-fit"
              style={{ height: 260, width: 410, objectFit: "contain" }}
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default EventsAndHighlights;
