import React from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  FormControl,
} from "react-bootstrap";
import connextLogo from "../../../src/images/connextlogo.png";
import "./Navbar.css";

const NavbarLayout = () => {
  return (
    <div className="container-fluid navigation">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className="mb-3 fixed-top nav_image"
      >
        <div
          id="wave"
          className="container-fluid  d-md-flex justify-content-end  "
        >
          <Navbar.Brand href="#">
            <img src={connextLogo} width="300" alt="Connextlogo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav " />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto col-12 d-flex justify-content-end">
              <div align="center" className="d-md-flex mt-4 mb-2">
                <NavDropdown
                  title={
                    <span className=" nav-text  text-dark fs-3 font nav_space">
                      home
                    </span>
                  }
                  className="bg-connext context"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="#tagline" className="fs-4">
                    TAGLINE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#overview" className="fs-4">
                    COMPANY OVERVIEW
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#mision" className="fs-4">
                    MISSION AND VISION
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#our-culture" className="fs-4">
                    OUR CULTURE
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={
                    <span className=" nav-text text-dark fs-3 font nav_space">
                      about
                    </span>
                  }
                  className="bg-connext context"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="#subsidiaries" className="fs-4">
                    OUR SUBSIDIARIES
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#awards-and-recognition"
                    className="fs-4"
                  >
                    AWARDS AND RECOGNITIONS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#events-and-highlights"
                    className="fs-4"
                  >
                    EVENTS AND HIGHLIGHTS
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={
                    <span className=" nav-text text-dark fs-3 font nav_space">
                      careers
                    </span>
                  }
                  className="bg-connext context"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="#why-work-with-us" className="fs-4">
                    WHY WORK WITH US
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#benefits" className="fs-4">
                    BENEFITS
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3" className="fs-4">
                    EMPLOYMENT VACANCIES
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3" className="fs-4">
                    HOW TO APPLY
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3" className="fs-4">
                    SEARCH JOBS
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#features">
                  <span className=" nav-text  text-dark fs-3 pr-1 font nav_space">
                    contact us
                  </span>
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavbarLayout;
