import "./ConnextVideo.css";
import nextArrow from "../../images/EVENTS-AND-HIGHLIGHTS/nextArrow-removebg-preview.png";
import prevArrow from "../../images/EVENTS-AND-HIGHLIGHTS/prevArrow-removebg-preview.png";
import yellowbg from "../../../src/images/background-image/Polygon 2.png";
import whatIcon from "../../images/backgroudImages/Group 7124.png";
import { Grid } from "@material-ui/core";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div id="events-and-highlights">
      <img
        src={nextArrow}
        className="nextArrow"
        style={{
          height: 40,
          width: 40,
          right: "-5%",
          position: "absolute",
          right: "-5%",
          bottom: 130,
        }}
        onClick={onClick}
      />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div>
      <img
        src={prevArrow}
        className="prevArrow"
        style={{
          height: 40,
          width: 40,
          left: "-5%",
          position: "relative",
          top: 140,
        }}
        onClick={onClick}
      />
    </div>
  );
}

const ConnextVideo = () => {
  return (
    <>
      <p id="our-culture">t</p>
      <div class="row video_head">
        <div className="col-md-6 col-sm-10 mt-5">
          <img className="what_icon" src={whatIcon} />
          <p className="what_with_text">What is up with</p>
          <p align="center" className=" mt-5 connext_text">
            Connext
          </p>
        </div>
        <div className="container col-12 col-md-6 p-5">
          <img
            className="shadow-none background_yellow d-none d-md-block"
            src={yellowbg}
          />
          <iframe
            className="award_video col-12"
            title="aaa"
            src="https://www.youtube.com/embed/LwyV2CiJ-CU"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div
        id="awards-and-recognition"
        className="container position-relative pt-5 awardtitle"
      >
        <div className="col-12 col-sm-12 text-center bg-connext-dark p-0 py-2 header_awards">
          <p className="display-5 fw-bold awardsreg_text">
            Awards and Recognitions
          </p>
          <div id="events-and-highlights" className="awards_box"></div>
        </div>
        <div className="container-fluid pt-5 "></div>
      </div>
    </>
  );
};

export default ConnextVideo;
