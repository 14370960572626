import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import landingPic from "../../images/TAGLINE-01.png";
import vectorIcon from "../../images/vector.png";
import "./CompanyOverView.css";
import missionIcon from "../../images/icons/mission-icon.png";
import visionIcon from "../../images/icons/Group 7317.png";
import connext from "../../images/logo/about-edited-size/CONNEXT INSURANCE AGENCY.png";
import dialex from "../../images/logo/about-edited-size/DIALEX.png";
import tara from "../../images/logo/about-edited-size/TARA-LOGO.png";
import autopoint from "../../images/logo/about-edited-size/AUTOPOINT.png";
import ecosystem from "../../images/logo/about-edited-size/ECOSYSTEM 1.png";
import econnext from "../../images/logo/about-edited-size/ECONNEXT2.png";
import hype from "../../images/logo/about-edited-size/MYHYPE-LOGO.png";
import xypher from "../../images/logo/about-edited-size/XYPHER SOLUTIONS.png";
import logobitz from "../../images/logo/about-edited-size/LOGOBIZ 2.png";
import insuranceIcon from "../../images/hidden_icons/Hover.png";
import collectionsIcons from "../../images/hidden_icons/Collections.png";
import ForwardingIcons from "../../images/hidden_icons/Forwarding serv.png";
import InformationIcons from "../../images/hidden_icons/Information serv.png";
import insuranceServices from "../../images/hidden_icons/Insurance serv.png";
import maintenanceIcon from "../../images/hidden_icons/Maintenance & Repair.png";
import marketingIcons from "../../images/hidden_icons/Group 7325.png";
import telecommunication from "../../images/hidden_icons/Telecommunications.png";
import transpoIcon from "../../images/hidden_icons/Transpo serv.png";
import circle1 from "../../images/backgroudImages/Group 7125.png";
import square1 from "../../images/backgroudImages/Group 7111.png";

const CompanyOverview = () => {
  // const classes = useStyles();
  const styles = {
    screen: {
      position: "absolute",
    },
    screen2: {
      position: "relative",
    },
  };
  return (
    <>
      <div id="tagline" className="landing_image_container">
        <img
          className="wave_absolute "
          src={vectorIcon}
          alt="wave"
          overflow="hidden"
        />
        <img
          className="image_front"
          style={styles.screen2}
          src={landingPic}
          fluid
          width="100%"
          alt="Landing Page Image"
        />
      </div>
      <Grid id="overview" container>
        <Grid item md={12} xs={12} align="center">
          <p className="display-6 companytitle company_overview">
            COMPANY OVERVIEW
          </p>
          <div className="bottom_header_box"></div>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          style={{
            padding: "50px  0px 100px",
          }}
          id="mission"
          align="center"
        >
          <img className="circle_1" src={circle1} />
          <p
            id="mision"
            style={{ width: "75%" }}
            className="text-justify fs-3 company_text"
          >
            Connext-Holding Inc. is a conglomerate group constituted by a
            diverse group of companies. Its foundation was laid out by the
            pioneer company, Connext Insurance Agency in 2015 which focuses
            mainly on marketing life and non-life insurance. This bold venture
            underpinned this conception of the other subsidiary companies that
            now make up a robust group of corporations that ultimately aims to
            uplift the quality of lives of the Filipinos and effectively
            contribute to economic growth.
          </p>
        </Grid>
        <div className="yellow_bar"></div>
        <div className="container">
          <div className="row">
            <div align="center" class="mb-3 col-md-6 col-sm-12">
              <div class="card-body">
                <img className="mission_icon" src={missionIcon} />
                <h5 class="card-title fs-1 p-md-2 p-3 fw-bold">Mission</h5>
                <p className="fs-4 p-md-2 p-3">
                  To be channel of blessing and opportunity for everyone equally
                  by creating leaders who create leaders.
                </p>
              </div>
            </div>
            <div align="center" class="col-md-6 col-sm-12">
              <img className="square1" src={square1} />
              <div class="card-body ">
                <img className="mission_icon" src={visionIcon} />
                <h5 class="card-title fs-1 p-md-3 p-3 fw-bold">Vision</h5>
                <p className="fs-4 p-md-2 p-3 text-center">
                  To be the leading organization that drives the people to
                  achieve their full potential, purpose, and dream.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="subsidiaries" className="container-fluid p-0 py-5 mb-3">
          <div className="container  text-center col-md-4  ">
            <p className="display-6 oursubsidaries_text">OUR SUBSIDIARIES</p>
            <div
              div
              className=" container-fluid  d-lg-flex  "
              id="subsidaries_header"
            ></div>
          </div>
        </div>
        <div className="container mt-4 context">
          <p className="fs-4 text-justify text-center lh-lg sub_description_text">
            Connext-Holding Inc. bolsters an operative model among its
            subsidiary companies by selecting a synergistic approach in
            diversifying the select industries in which we chose to venture
            into, namely:
          </p>
        </div>
      </Grid>
      <Grid container>
        {/* <Grid item md={3}></Grid> */}
        <Grid item md={4} xs={12}>
          <div className="container-fluid d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={connext}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a
                href="https://www.facebook.com/connextinsuranceagency"
                target="_blank"
              >
                <img src={insuranceIcon} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid  d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={dialex}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a href="https://www.facebook.com/dialexinc" target="_blank">
                <img src={telecommunication} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid  d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={tara}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a href="https://www.facebook.com/TaraPHOfficial" target="_blank">
                <img src={transpoIcon} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={autopoint}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a
                href="https://www.facebook.com/AutoPointGarageInc"
                target="_blank"
              >
                <img src={maintenanceIcon} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "30%", width: "30%" }}
              src={ecosystem}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ecosystem_backimage">
              <a href="https://www.facebook.com/ecosystemapp" target="_blank">
                <img className="ecosystem_image" src={marketingIcons} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={econnext}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a href="https://www.facebook.com/TaraPHOfficial" target="_blank">
                <img src={insuranceServices} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "10%", width: "27%" }}
              src={hype}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a href="https://www.facebook.com/HypePilipinas" target="_blank">
                <img src={ForwardingIcons} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={xypher}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a
                href="https://www.facebook.com/xyphersolutionsph"
                target="_blank"
              >
                <img src={InformationIcons} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className="container-fluid mt-5 d-flex justify-content-center position-relative">
            <img
              className="subsidiaries"
              style={{ height: "60%", width: "60%" }}
              src={logobitz}
              // className="col-md-4"
              alt="CONNEXT INSURANCE AGENCY"
            />
            <div className="container position-absolute text-center hidden ">
              <a
                href="https://www.facebook.com/AutoPointGarageInc"
                target="_blank"
              >
                <img src={collectionsIcons} />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyOverview;
