// import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./AwardsAndRecognition.css";
import Slider from "react-slick";
import nextArrow from "../../images/EVENTS-AND-HIGHLIGHTS/nextArrow-removebg-preview.png";
import prevArrow from "../../images/EVENTS-AND-HIGHLIGHTS/prevArrow-removebg-preview.png";
import yellowbg from "../../../src/images/background-image/Polygon 2.png";
import OurCulture from "../../images/about/OurCulture.png";
import award1 from "../../images/AWARDS-AND-RECOGNITIONS/DSC_0238.JPG";
import award2 from "../../images/AWARDS-AND-RECOGNITIONS/DSC_0680.JPG";
import award3 from "../../images/AWARDS-AND-RECOGNITIONS/IMG_3960.JPG";
import award4 from "../../images/AWARDS-AND-RECOGNITIONS/IMG_8573.JPG";
import award5 from "../../images/AWARDS-AND-RECOGNITIONS/IMG_8594.JPG";
import award6 from "../../images/AWARDS-AND-RECOGNITIONS/Ms. Earth Philippines 2019.JPG";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div>
      <img
        src={nextArrow}
        className="nextArrow"
        style={{
          height: 40,
          width: 40,
          right: "-5%",
          position: "absolute",
          right: "-5%",
          bottom: 130,
        }}
        onClick={onClick}
      />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div>
      <img
        src={prevArrow}
        className="prevArrow"
        style={{
          height: 40,
          width: 40,
          left: "-5%",
          position: "relative",
          top: 140,
        }}
        onClick={onClick}
      />
    </div>
  );
}

const AwardsAndRecognition = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    draggable: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="container position-relative pt-5 awardtitle">
        <div className="container-fluid pt-5 ">
          <Slider {...settings}>
            <div className="container">
              <img
                src={award1}
                className="img-object-fit"
                style={{ height: 250, objectFit: "contain" }}
              />
            </div>
            <div className="container">
              <img
                src={award2}
                className="img-object-fit"
                style={{ height: 250, objectFit: "contain" }}
              />
            </div>
            <div className="container">
              <img
                src={award3}
                className="img-object-fit"
                style={{ height: 250, objectFit: "contain" }}
              />
            </div>
            <div className="container">
              <img
                src={award4}
                className="img-object-fit"
                style={{ height: 250, width: 400, objectFit: "contain" }}
              />
            </div>
            <div className="container">
              <img
                src={award5}
                className="img-object-fit"
                style={{
                  height: 250,
                  width: 400,
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="container">
              <img src={award6} style={{ height: 250, objectFit: "contain" }} />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AwardsAndRecognition;
