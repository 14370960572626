import React from "react";
import { Grid } from "@material-ui/core";

import CompanyOverview from "../components/CompanyOverView/CompanyOverview";
import Careers from "../components/Careers/Careers";
import NavbarLayout from "../components/Navbar/NavbarLayout";
import ConnextVideo from "../components/AwardsAndRecognition/ConnextVideo";
import MultiItemCarousel from "../components/Carousel/MultiItemCarousel";
import AwardsAndRecognition from "../components/Carousel/AwardsAndRecognition.js";
import EventsAndHighlights from "../components/EventsAndHighlights/EventsAndHighlights";
import Footer from "../components/Footer/Footer";
const Home = () => {
  return (
    <>
      <NavbarLayout />
      <CompanyOverview />
      <ConnextVideo />
      <AwardsAndRecognition />
      <EventsAndHighlights />
      <Careers />
      <Footer />
    </>
  );
};

export default Home;
